import { useQuery } from "@tanstack/react-query";

import { useAuth, axios, authorizedAxios } from "@moonscape/shared";

export const useTaxEventsData = ({
  page,
  taxYear,
  // isSearching,
  // searchedValue,
  onLoading,
}) => {
  const { getToken, user } = useAuth();

  // userId
  const email = user?.email;

  console.log("[cc] email", email);

  console.log("useTaxEventsData-page", page);
  // console.log(`Searched DATA FROM useTaxEventsData ${searchedValue}`);
  let exchangeQueryParam = "";
  let assetQueryParam = "";

  const getTaxEventsData = async () => {
    try {
      const token = await getToken();

      // console.log("token tax: ", token);
      // let url = isSearching
      //   ? `/tax/search/${user?.email}/?search=${searchedValue}&cursor=${page}`
      //   : `/tax/${user?.email}?cursor=${page}${exchangeQueryParam}${assetQueryParam}`;
      // TODO: add search if i want
      let url = `/tax/events/${taxYear}?cursor=${page}&limit=5`; //${exchangeQueryParam}${assetQueuseTaxEventsDataryParam}`;

      const res = await authorizedAxios(token).get(
        // TODO: add filter here for exchange and asset
        url
      );
      console.log("useTaxEventsData() url ", url);

      console.log("/trades res", res.data, user?.email);
      return res.data;
    } catch (error) {
      console.log("[useTaxEventsData] error ", JSON.stringify(error, null, 2));
      return { error };
      // throw error;
    }
  };

  // useQuery key to cache
  // const useQueryKey = !isSearching
  //   ? `trades-${exchange}-${asset}`
  //   : `trades-search-${searchedValue}`;
  const useQueryKey = `tax-${taxYear}`;

  const {
    isLoading: isQueryLoading,
    isSuccess,
    isIdle,
    error,
    isError,
    data: taxEvents,
    isPreviousData,
    isFetching,
  } = useQuery({
    queryKey: [useQueryKey, page],
    queryFn: getTaxEventsData,

    // The query will not execute until the userId exists
    enabled: !!email,

    keepPreviousData: true,
    refetchOnWindowFocus: true,
  });
  // isSearching && isFetching ? onLoading(isFetching) : onLoading(false);

  return { isQueryLoading, isIdle, error, taxEvents, isPreviousData };
};
