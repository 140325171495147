import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Alert, AlertWithComponent, ButtonLink, Screen } from "@/components";
import { useTaxOperationStatus } from "@/hooks";
import { clsx } from "clsx";
import { Link } from "react-router-dom";

import {
  useTaxEventsPagination,
  useProfile,
  getCurrentTaxYearCGTRate,
  getCGTRateCategory,
  useCapitalGainsData,
  TaxJurisdiction,
} from "@moonscape/shared";
import { useTriggerTaxReport } from "./useTriggerTaxReport";
import { SettingsTabs } from "../SettingsScreen/SettingsTabs";
import { Alert as OnboardingAlert } from "@/components/OnboardingAlert";

import dayjs from "dayjs";
import { TaxEventsTable } from "./components/TaxEventsTable";
import { IndividualInformation, TaxSummary } from "./components";
import { FAQsUK, FAQsUS } from "./components/FAQ";

import { CURRENT_TAX_YEAR, TAX_YEARS } from "@moonscape/shared";

export const TaxScreen = (props) => {
  let history = useHistory();
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();

  // reminders
  const hasReminders = false;

  // for tax operation status
  const { operationStatus } = useTaxOperationStatus();
  const isTaxOperationRunning = operationStatus?.status === "running";
  const taxStartedTime = dayjs(operationStatus?.last_updated).fromNow();

  const [selectedTaxYear, setSelectedTaxYear] = useState(CURRENT_TAX_YEAR);

  // const tabs = [
  //   { name: "Capital gains", href: `${path}/cgt`, current: true },
  //   { name: "Income", href: `${path}/income`, current: false },
  //   { name: "Holdings", href: `${path}/holdings`, current: false },
  //   { name: "HMRC", href: `${path}/hmrc`, current: false },
  // ];

  // const [activeTab, setActiveTab] = useState(
  //   tabs.filter((tab) => tab.href === pathname)
  // );
  const { profile } = useProfile();
  const hasLinkedExchangeAccount = profile?.hasLinkExchangeAccounts;

  const [showReviewTxns, setShowReviewTxns] = useState(0);
  const [showStats, setShowStats] = useState(true);
  const [showAlert, setShowAlert] = useState(true);
  const { taxEvents } = useTaxEventsPagination({ taxYear: selectedTaxYear });
  const { recalculateTax } = useTriggerTaxReport();
  const hasTaxEvents = taxEvents?.items?.length > 0;

  const { cgt_report: cgt } = useCapitalGainsData({
    taxYear: CURRENT_TAX_YEAR,
  });

  const hasMissingTaxInfo = !profile?.hasRequiredTaxInfo;

  // data to display - formatted or processed
  const currentCGTRate = getCurrentTaxYearCGTRate(profile)?.rate;
  const displayCGTRate = currentCGTRate ? `${currentCGTRate}%` : "-";
  console.log("currentCGTRate", currentCGTRate);

  return (
    <Screen heading="Tax" className="bg-gray-50">
      <main className="mx-auto p-8">
        {!hasLinkedExchangeAccount && <OnboardingAlert />}

        {isTaxOperationRunning && (
          <AlertWithComponent>
            <p>
              Calculating tax report for {selectedTaxYear} in progress. Started{" "}
              {taxStartedTime}.
            </p>
          </AlertWithComponent>
        )}

        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col justify-between items-stretch bg-white p-6 px-8 rounded-lg ">
            <div className="flex justify-between items-start">
              <h2 className="text-xl font-bold text-gray-900">Tax Year</h2>
              <select
                id="taxyear"
                name="taxyear"
                className="form-select p-2 block w-1/4 mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 outline outline-2 outline-gray-100 outline-offset-2"
                value={selectedTaxYear}
                onChange={(e) => setSelectedTaxYear(e.target.value)}
              >
                {TAX_YEARS.map(({ taxYear, value }) => (
                  <option key={value} value={value}>
                    {taxYear}
                  </option>
                ))}
              </select>
            </div>
            <p className="text-grey-700">April 5 2022 - 5 April 2023</p>
          </div>

          {/* <SettingsTabs pathname={pathname} tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} /> */}

          {/* main content with blocks */}

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 my-6">
            <div className="flex flex-col space-y-4 lg:col-span-2 ">
              {hasMissingTaxInfo && (
                <div className="bg-white p-6 rounded-lg ">
                  <h2 className="text-lg font-medium mb-6">Reminders</h2>
                  <Alert variant="info">
                    <p>
                      <Link to={`/settings/tax`}>
                        Click here to complete your Tax Settings for tax
                        calculations
                      </Link>
                    </p>
                  </Alert>
                  {/* <Alert variant="info">
                    <p>
                      You have not reviewed your transactions for the selected
                      tax year.{" "}
                    </p>
                  </Alert> */}
                </div>
              )}
              {hasReminders && (
                <div className="bg-white p-6 rounded-lg ">
                  <h2 className="text-lg font-medium mb-6">Reminders</h2>
                  <Alert variant="warning">
                    <p>
                      You have not reviewed your transactions for the selected
                      tax year.{" "}
                      <a
                        href="/review-transactions"
                        style={{ textDecoration: "underline" }}
                      >
                        Review now
                      </a>
                    </p>
                  </Alert>
                  <Alert variant="info">
                    <p>
                      You have not reviewed your transactions for the selected
                      tax year.{" "}
                    </p>
                  </Alert>
                </div>
              )}
              <div className="bg-white p-6 rounded-lg  ">
                <h2 className="text-lg font-medium">Tax Events</h2>
                <TaxEventsTable taxYear={selectedTaxYear} />
              </div>
            </div>

            <div className="flex flex-col space-y-4">
              <div className="bg-white p-6 rounded-lg min-w-80  ">
                <TaxSummary
                  data={{
                    taxYear: selectedTaxYear,
                    disposals: cgt?.disposals,
                    estimateGain: cgt?.total_gain,
                    proceeds: cgt?.total_proceeds,
                    estimateLoss: cgt?.total_loss,
                    allowance: cgt?.allowance,
                    estimateTax: cgt?.estimated_tax_due,
                  }}
                />
              </div>

              <div className="bg-white p-6 rounded-lg  min-w-80 ">
                <IndividualInformation
                  data={
                    // Example usage
                    {
                      taxJurisdiction: profile?.taxJurisdiction,
                      currentIncomeTaxRate: getCGTRateCategory(
                        currentCGTRate,
                        profile
                      ),
                      currentCGTTaxRate: displayCGTRate,

                      baseCurrency: profile?.baseCurrency,
                      costBasisMethod: profile?.accountingMethod,
                      disposals: cgt?.disposals,
                      estimateGain: cgt?.total_gain,
                      proceeds: cgt?.total_proceeds,
                      estimateLoss: cgt?.total_loss,
                      allowance: cgt?.allowance,
                      estimateTax: cgt?.estimated_tax_due,
                    }
                  }
                />
              </div>
              <div className="bg-white p-6 rounded-lg  min-w-80 ">
                {profile?.taxJurisdiction == TaxJurisdiction.UK && <FAQsUK />}
                {profile?.taxJurisdiction == TaxJurisdiction.US && <FAQsUS />}
              </div>
            </div>
          </div>

          <Switch>
            <Route path={`${path}/cgt`} exact>
              {/* Tax CGT Page Content */}
            </Route>
            <Route path={`${path}/income`}>
              {/* Tax Income Page Content */}
            </Route>
            <Route path={`${path}/hmrc`}>{/* Tax HMRC Page Content */}</Route>
            <Route path={`${path}/holdings`}>
              {/* Tax Holdings Page Content */}
            </Route>
          </Switch>
        </div>
      </main>
    </Screen>
  );
};
