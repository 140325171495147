import { CURRENT_TAX_YEAR, TAX_YEARS } from "../config/taxConfig";
import { TaxJurisdiction } from "../types";

export const getCurrentTaxYearCGTRate = (profile) => {
  return profile?.taxRates?.find((rate) => rate?.year === CURRENT_TAX_YEAR);
};

export const getTaxYearByValue = (value) => {
  const taxYearObj = TAX_YEARS?.find((year) => year.value === value);
  return taxYearObj ? taxYearObj.taxYear : null;
};

export const getCGTRateCategory = (cgtRate, profile) => {
  if (profile?.taxJurisdiction === TaxJurisdiction.UK) {
    if (cgtRate === 10) {
      return "Basic rate";
    } else if (cgtRate === 20) {
      return "Higher rate";
    }
  } else if (profile?.taxJurisdiction === TaxJurisdiction.UK) {
  }
  return "-";
};
